




















































































































import { Component, Vue } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Consigne } from "@/api/models/dossiers/consignes";
import NotificationDropdown from "@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue";

@Component({
  components: {
    NotificationDropdown,
  },
})
export default class DetailsConsigne extends Vue {
  consigne: Consigne | null = null;

  created() {
    this.getConsigne();
  }

  async getConsigne() {
    await this.$http.ressifnet.consignes.getDetails(this.$route.params.id).then(
      (response: Consigne) => {
        this.consigne = response;

       if (this.$store?.state?.user?.user?.uid) {
          this.$store.dispatch('consignes/fetchConsigne')
        }
      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      },
    );
  }

  async traiterConsigne() {
    this.consigne?.entrepriseId
      ? await this.$http.ressifnet.consignes
          .updateTraiteeForConsigneEntreprise(this.consigne!.entrepriseId, this.consigne!.id)
          .then(
            (response: any) => {
              if (this.consigne?.changeReferent) {
                this.$swal({
                  title: "Changement de référent",
                  text: "Vous venez d'être assigné en tant que référent de ce dossier",
                  icon: "warning",
                  customClass: {
                    confirmButton: "btn btn-warning",
                  },
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                });
              } else {
                successAlert.fire({
                  title: "Signaler la consigne entreprise comme traitée",
                  text: "Signalement effectué avec succès",
                });
              }

              this.getConsigne();
            },
            (error: any) => {
              if (
                error?.response?.data?.errors &&
                Object.keys(error.response.data.errors).length
              ) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                errorAlert.fire({
                  text: error?.response?.data?.detail ?? error.message,
                });
              }
            },
          )
      : await this.$http.ressifnet.consignes
          .updateTraiteeForConsigneDossier(this.consigne!.dossierId, this.consigne!.id)
          .then(
            (response: any) => {
              if (this.consigne?.changeReferent) {
                this.$swal({
                  title: "Changement de référent",
                  text: "Vous venez d'être assigné en tant que référent de ce dossier",
                  icon: "warning",
                  customClass: {
                    confirmButton: "btn btn-warning",
                  },
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                });
              } else {
                successAlert.fire({
                  title: "Signaler la consigne dossier comme traitée",
                  text: "Signalement effectué avec succès",
                });
              }

              this.getConsigne();
            },
            (error: any) => {
              if (
                error?.response?.data?.errors &&
                Object.keys(error.response.data.errors).length
              ) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                errorAlert.fire({
                  text: error?.response?.data?.detail ?? error.message,
                });
              }
            },
          );
  }

  getClassImportance(importance: number) {
    switch (importance) {
      case 1:
        return "bg-light-success";
      case 2:
        return "bg-light-warning";
      case 3:
        return "bg-light-danger";
    }
  }

  backToList() {
    this.$emit("back-to-list");
  }
}
